import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="position"
export default class extends Controller {
  static targets = [ "click" ]
  connect() {
  }
  click() {
    // this.clickTargets.forEach(target => target.click())
    // data-position-target="position"

  }
  update_position(){
    window.desired_row_number = this.element.closest('tr').rowIndex;
    window.desired_row_number_default = false;
  }
  update_position_default(){
    window.desired_row_number_default = true;
  }
  add_position_to_url(){
    if(window.desired_row_number_default === true){
      window.desired_row_number = document.querySelector("#output-table .table-panel-adr").rows.length-1;
    }
    this.element.parentElement.action = this.element.parentElement.action+"&position="+window.desired_row_number;
  }

}
