import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {
  connect() {
    let table_row = document.querySelector(".table-panel-adr").rows
    if(table_row.length > 2){
      for(let i=2; i<table_row.length; i++){
        if(table_row[i].querySelectorAll("tr .has-popover").length < 1){
          let table_cells= table_row[i].cells
          for(let j=table_cells.length-1; j>2; j--){
            table_row[i].deleteCell(j);
          }
          let cell_with_notice = table_row[i].insertCell(3);
          cell_with_notice.innerHTML = "Caution: the adverse effects of this drug are possibly not completely processed, please check the Prescriber Information.";
          cell_with_notice.className = 'cell-with-notice';
          cell_with_notice.colSpan = 34;
        }
      }
    }
    if((table_row.length > 3) && (document.getElementById('drug-interaction-button').disabled == true)){
      document.getElementById('drug-interaction-button').disabled = false;
    }
    else if(table_row.length <=3){
      document.getElementById('drug-interaction-button').disabled = true;
    }
    if((table_row.length > 2) && (document.getElementById('medicine-packages-button').disabled == true)){
      document.getElementById('medicine-packages-button').disabled = false;
    }
    else if(table_row.length <=2){
      document.getElementById('medicine-packages-button').disabled = true;
    }
  }
  labelEmpty(){

  }
}
