import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ['content', 'loading'];

    connect(){
    }

    displayLoading(event) {
        if(!document.querySelectorAll('[busy]').length > 0){
            document.querySelector(".general-loader").style.display="block";
        }
    }

    hideLoading(event) {
        if(document.querySelectorAll('[busy]').length == 1){
            document.querySelector(".general-loader").style.display="none";
        }
    }
}