import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setting"
export default class extends Controller {
  connect() {
    if(document.querySelector(".table-panel-adr")){
      let table_row = document.querySelector(".table-panel-adr").rows;
      if((table_row.length > 3) && (document.getElementById('drug-interaction-button').disabled == true)){
        document.getElementById('drug-interaction-button').disabled = false;
      }
      else if(table_row.length <=3){
        document.getElementById('drug-interaction-button').disabled = true;
      }
      if((table_row.length > 2) && (document.getElementById('medicine-packages-button').disabled == true)){
        document.getElementById('medicine-packages-button').disabled = false;
      }
      else if(table_row.length <=2){
        document.getElementById('medicine-packages-button').disabled = true;
      }
    }
  }
}
