import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    this.my_tooltip = new bootstrap.Tooltip(this.element,{
      selector: '[data-bs-toggle="tooltip"]',
      html: true,
      container: 'body',
      placement: 'top',
      title: this.element.dataset.title,
    });
  }

  disconnect(){
    this.my_tooltip.dispose(); //it should be implemented to avoid memory leaks.
  }

  dismiss() {
  }
  show(){
    this.my_tooltip.toggle();
  }
}