import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="iframeloading"
export default class extends Controller {
  static targets = ['frame', ''];
  connect() {
    var target_iframe = this.element.firstChild
    target_iframe.addEventListener('load', this.hideIframeLoading.bind(this.element));
    target_iframe.addEventListener('error', this.errorIframeLoading.bind(this.element));



    // this.frameTarget.addEventListener('load', this.hide.bind(this));


    //
    // const iframe = document.querySelector('.my-iframe');
    //
    // iframe.onload = function() {
    //   console.log("The iframe is loaded");
    // }
    // iframe.onerror = function() {
    //   console.log("Something wrong happened");
    // }

  }

  displayIframeLoading(event) {
    //
    // if(!document.querySelectorAll('[busy]').length > 0){
    //   document.querySelector(".general-loader").style.display="block";
    // }
  }

  hideIframeLoading(event) {
    event.target.parentElement.previousSibling.style.display="none"
  }
  errorIframeLoading(event){
    console.log("Something wrong happened");
  }

}

