import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";
// Connects to data-controller="learnmore"
export default class extends Controller {
  connect() {
  }
  update_text(){
    let target_scroll = this.element.dataset.bsTarget;
    let target_scroll_text = target_scroll.substring(1,target_scroll.length);
    if (this.element.innerHTML === "Learn More") {
      this.element.innerHTML = "Learn Less";
      setTimeout(function(){
        document.getElementById(target_scroll_text).scrollIntoView({behavior: "smooth"});
      }, 100);
    } else {
      this.element.innerHTML = "Learn More";
    }
  }
}
