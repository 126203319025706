import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
export default class extends Controller {
    connect() {
        this.modal = new Modal(this.element);
        this.modal.show();
        // this.element.addEventListener('hidden.bs.modal', (event) => {
        //     this.element.remove();
        // })
        // document.addEventListener('turbo:submit-end', this.handleSubmit)

        // this.element.addEventListener('hidden.bs.modal', (event) => {
        //     // this.element.remove();
        //     let backdrop = document.querySelector(".modal-backdrop");
        //     if (backdrop) {
        //         backdrop.remove();
        //     }
        //     // this.element.closest("turbo-frame").src = undefined
        // })
        document.addEventListener('turbo:submit-end', this.handleSubmit)

        //NEED
        // let backdrop = document.querySelector(".modal-backdrop");
        // if (backdrop) {
        //     backdrop.remove();
        // }


    }
    close() {
        // Remove the modal element so it doesn't blanket the screen
        // this.modal.hide()
        // this.element.remove()


        // Remove src reference from parent frame element
        // Without this, turbo won't re-open the modal on subsequent clicks
        // this.element.closest("turbo-frame").src = undefined
    }
    handleKeyup(e) {
        if (e.code == "Escape") {
            this.close()
        }
    }
    handleSubmit = (e) => {
        if (e.detail.success) {
            this.close()
        }
    }
    setSrc({ params: { url } }) {
        document.querySelector('#b-modal-adr').src = url
    }
    removeSrc() {
        document.querySelector('#b-modal-adr').src = undefined
    }
}
