import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multistep"
export default class extends Controller {
  static targets = ["step"]

  connect() {
    this.showStep(1)
  }

  next(event) {
    event.preventDefault()
    const step = event.currentTarget.getAttribute("data-step")
    if (this.validateStep(step-1)) {
      this.showStep(parseInt(step));
    }
  }

  prev(event) {
    event.preventDefault()
    const step = event.currentTarget.getAttribute("data-step")
    this.showStep(parseInt(step))
  }

  showStep(step) {
    this.stepTargets.forEach((target) => {
      const id = parseInt(target.getAttribute("data-id"))
      if (id === step) {
        target.classList.remove("d-none")
      } else {
        target.classList.add("d-none")
      }
    })
  }

  checkFormValidity(step) {
    // Get the form element for the current step
    const form = document.querySelector(`[data-id="${step}"] form`);

    // Check the form's validity using HTML5 built-in validation
    if (form.checkValidity()) {
      return true; // Validation passed
    } else {
      form.reportValidity(); // Display browser's error messages
      return false; // Validation failed
    }
  }

  validateStep(step) {
    // Get the form fields within the current step's div
    const fields = this.stepTargets[step - 1].querySelectorAll("input[required], select[required]");

    // Check the validity of the form fields in the current step
    let isValid = true;
    fields.forEach((field) => {
      if (!field.checkValidity()) {
        field.reportValidity();
        isValid = false;
      }
    });

    return isValid;
  }



}
