// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"



// Turbo.StreamActions.redirect = function () {
//     Turbo.visit(this.target);
//
// };

Turbo.StreamActions.redirect = function () {
    const url = this.getAttribute('url') || '/'
    Turbo.visit(url, { frame: '_top', action: 'advance' })
}

// document.addEventListener("turbo:frame-missing", event => {
//     if (event.detail.response.redirected) {
//         event.preventDefault()
//         event.detail.visit(event.detail.response)
//     }
// })
// document.addEventListener("turbo:visit", event => {
//     console.log("turbo:visit>>>");
//     // if (event.detail.response.redirected) {
//     //     event.preventDefault()
//     //     event.detail.visit(event.detail.response)
//     //
//     // }
// })

document.addEventListener("turbo:load", () => {




    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //     return new bootstrap.Tooltip(tooltipTriggerEl)
    // })
    //
    // var myDefaultAllowList = bootstrap.Tooltip.Default.allowList
    // myDefaultAllowList.table = []
    // myDefaultAllowList.tbody = []
    // myDefaultAllowList.tr = []
    // myDefaultAllowList.td = []

    // popover if required turn it on
    // var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    //     return new bootstrap.Popover(popoverTriggerEl,{
    //         container: 'body',
    //         html: true,
    //         selector: '.has-popover',
    //         allowList: myDefaultAllowList,
    //         title: (() => document.querySelector('#popoverTitle')),
    //         content: (() => document.querySelector('#popoverTable'))
    //
    //     })
    // })

    // new bootstrap.Popover(document.body, {
    //     selector: '.has-popover',
    //     html: true,
    //     container: 'body',
    //     // content: function() {
    //     //     var content = $(this).attr("data-bs-content");
    //     //     return $(content).children(".popover-body").html();
    //     // },
    // });




    // new bootstrap.Popover(document.body, {
    //     selector: '.has-popover',
    //     html: true,
    //     container: 'body',
    //     allowList: myDefaultAllowList,
    //     title: (() => document.querySelector('#popoverTitle')),
    //     content: (() => document.querySelector('#popoverTable'))
    // });
    // window.onbeforeunload = function() {
    //     // get the destination URL of the link
    //     var destination = document.activeElement.href;
    //     // destination is showing nothing so code is not working
    //     // check if it matches the URL of the page that you want to target
    //     if (destination === "http://localhost:3000/subscriptions") {
    //         // return a message to show the dialog
    //         return "Data will be lost if you leave the page, are you sure?";
    //     }
    //     // no match, do nothing
    // };
    // you can use https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event this link and then use given code in turbo visit
    // following code(onbeforeunload) is working
    // window.onbeforeunload = function(event) {
    //
    //     return "Data will be lost if you leave the page, are you sure?";
    // };
    //check following link for better guidance onbeforeunload
    //https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

    // window.addEventListener('beforeunload', function (event) {
    //     // console.log(event);
    //     // event.stopImmediatePropagation();
    //     // return null;
    //     // return "Data will be lost if you leave the page, are you sure?";
    // });



    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl) {
        return new bootstrap.Toast(toastEl)
    })
    var myModal = document.getElementById('newMedicineModal')
    var myInput = document.getElementById('medicine_search_query')
    myModal.addEventListener('shown.bs.modal', function () {
        myInput.focus()
    })
    // $('[data-toggle="toast"]').toast('show')

    //******************iframe



})

// document.addEventListener("turbo:frame-missing", event => {
//     if (event.detail.response.redirected) {
//         event.preventDefault()
//         event.detail.visit(event.detail.response)
//     }
//     console.log("redirected");
// })
// document.addEventListener("turbo:visit", event => {
//     console.log("turbo:visit");
//     // if (event.detail.response.redirected) {
//     //     event.preventDefault()
//     //     event.detail.visit(event.detail.response)
//     //
//     // }
// })

// document.addEventListener("turbo:render", () => {
//     console.log("turbo:frame-load")
//
//     var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//     var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//         return new bootstrap.Popover(popoverTriggerEl,{
//             container: 'body',
//             html: true,
//             selector: '.has-popover'
//
//         })
//     })
//
// })
// document.getElementById("toastbtn").onclick = function() {
// var toastElList = [].slice.call(document.querySelectorAll('.toast'))
// var toastList = toastElList.map(function(toastEl) {
// return new bootstrap.Toast(toastEl)
// })
// toastList.forEach(toast => toast.show())
// }

// const newConfirmMethod = (message, formElement) => confirm("overriden method")
//
// Turbo.setConfirmMethod(newConfirmMethod)

//it is not working now.
//check following links
//https://gorails.com/episodes/turbo-data-confirm-method-and-disable
//https://user-images.githubusercontent.com/67093/151271318-5034bbff-6b24-462f-89f7-8e6e3f8d0466.mp4
//https://gorails.com/episodes/custom-hotwire-turbo-confirm-modals without having any answer
// https://github.com/hotwired/turbo/pull/199
//current problem is the propagations don't stopped and system continue
// Turbo.setConfirmMethod((message, element) => {
//     console.log(message, element)
//     let dialog = document.getElementById("turbo-confirm")
//     dialog.querySelector("p").textContent = message
//     dialog.showModal()
//     return new Promise((resolve, reject) => {
//         dialog.addEventListener("close", () => {
//             console.log("i am called everytime")
//             resolve(dialog.returnValue == "confirm")
//         }, { once: true })
//     })
// })

//apparently functions are not working

